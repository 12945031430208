import clsx from 'clsx';
import {LandingPageContent} from './landing-page-content';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {Button, ButtonProps} from '@common/ui/buttons/button';
import {MixedImage} from '@common/ui/images/mixed-image';
import {Footer} from '@common/ui/footer/footer';
import {Trans} from '@common/i18n/trans';
import {Link} from 'react-router-dom';
import {createSvgIconFromTree} from '@common/icons/create-svg-icon';
import {MenuItemConfig} from '@common/core/settings/settings';
import {Fragment, useState ,useEffect } from 'react';
import {DefaultMetaTags} from '@common/seo/default-meta-tags';
import {useSettings} from '@common/core/settings/use-settings';
import {PricingTable} from '@common/billing/pricing-table/pricing-table';
import {BillingCycleRadio} from '@common/billing/pricing-table/billing-cycle-radio';
import {UpsellBillingCycle} from '@common/billing/pricing-table/find-best-price';
import {useProducts} from '@common/billing/pricing-table/use-products';
import { Player } from '@lottiefiles/react-lottie-player';

interface ContentProps {
  content: LandingPageContent;
}

export function LandingPage() {
  const settings = useSettings();
  const homepage = settings.homepage as { appearance: LandingPageContent };

  const showPricing =
    homepage.appearance.showPricing && settings.billing.enable;

  return (
    <Fragment>
      <DefaultMetaTags/>
      <Fragment>
        <HeroHeader content={homepage.appearance}/>
        <SupportedBy content={homepage.appearance}/>
        <PrimaryFeatures content={homepage.appearance}/>
        <div className="mt-100 h-1 bg-divider"/>
        <SecondaryFeatures content={homepage.appearance}/>
        {showPricing && <PricingSection content={homepage.appearance}/>}
        <Goal content={homepage.appearance}/>
        <Features content={homepage.appearance}/>
        <BottomCta content={homepage.appearance}/>

        <Footer className="landing-container"/>

      </Fragment>
    </Fragment>
  );
}

function HeroHeader({content}: ContentProps) {
  const {
    headerTitle,
    headerSubtitle,
    headerImage,
    headerImageOpacity,
    actions,
    headerOverlayColor1,
    headerOverlayColor2,
  } = content;
  let overlayBackground = undefined;

  if (headerOverlayColor1 && headerOverlayColor2) {
    overlayBackground = `linear-gradient(45deg, ${headerOverlayColor1} 0%, ${headerOverlayColor2} 100%)`;
  } else if (headerOverlayColor1) {
    overlayBackground = headerOverlayColor1;
  } else if (headerOverlayColor2) {
    overlayBackground = headerOverlayColor2;
  }
  useEffect(() => {
    // Define an array of placeholder texts for each input
    const placeholders = [
      ["A landing page for a SaaS marketing platform", "A professional website for a designer agency", "A Portfolio Page for a musician called Jack "],
      ["A landing page for a SaaS marketing platform", "A professional website for a designer agency", "A Portfolio Page for a musician called Jack "]
    ];

    // Function to simulate typing animation for a specific input
    function typePlaceholder(inputId: string) {
      const input = document.getElementById(inputId);
      if (input) {
        let placeholderIndex = 0;
        let placeholderInterval: NodeJS.Timeout;

        // Start typing animation
        function animatePlaceholder() {
          const placeholder = placeholders[Number(inputId.slice(-1)) - 1][placeholderIndex];
          let index = 0;

          // Clear existing interval if any
          clearInterval(placeholderInterval);

          // Start typing animation
          placeholderInterval = setInterval(() => {
            if (input) {
              // @ts-ignore
              input.placeholder = placeholder.substring(0, index++);
              if (index > placeholder.length) {
                // If typing animation finished, move to the next placeholder
                clearInterval(placeholderInterval);
                placeholderIndex = (placeholderIndex + 1) % placeholders[Number(inputId.slice(-1)) - 1].length;
                setTimeout(animatePlaceholder, 800); // Start typing next placeholder after a delay
              }
            }
          }, 60); // Typing speed (milliseconds)
        }

        // Start typing animation for the first placeholder
        animatePlaceholder();
      }
    }

    // Start typing animation for each input
    typePlaceholder("prompt1");
    typePlaceholder("prompt2");
  }, []);
  return (
    <header className="relative isolate mb-14 overflow-hidden md:mb-60">
      <img
        data-testid="headerImage"
        src={headerImage}
        style={{
          opacity: headerImageOpacity,
        }}
        alt=""
        width="2347"
        height="1244"
        decoding="async"
        loading="lazy"
        className="absolute left-1/2 top-1/2 z-20 max-w-none -translate-x-1/2 -translate-y-1/2"
      />
      <div
        className="absolute z-10 h-full w-full "
        style={{background: overlayBackground}}
      />
      <div className="relative z-30 flex h-full flex-col">
        <Navbar
          color="transparent"
          className="flex-shrink-0"
          menuPosition="homepage-navbar"
          primaryButtonColor="paper"
        />
        <div
          className="mx-auto flex max-w-850 flex-auto flex-col items-center justify-center px-14 py-50 text-center text-white lg:py-90">
          {headerTitle && (
            <h1
              className="text-3xl font-normal md:text-5xl"
              data-testid="headerTitle"
            >
              <Trans message={headerTitle}/>
            </h1>
          )}
          {headerSubtitle && (
            <div
              className="max-auto mt-24 max-w-640 text-lg tracking-tight md:text-xl"
              data-testid="headerSubtitle"
            >
              <Trans message={headerSubtitle}/>
            </div>
          )}

          <div className="hero-form field field-grouped" >
          <div className="control control-expanded" style={{ position: 'relative' ,width: '480px'}}>
            <input
              className="input"
              type="text"
              id="prompt1"
              name="prompt1"
              placeholder="A landing page for a SaaS marketing platform"

            />
            <a
              className="button button-primary button-block button-generate"

              href={actions.cta1.action}
            >
              {actions.cta1.label}
            </a>
          </div>
          </div>
          <div
            className="flex min-h-50 gap-20 pb-30 pt-70 empty:min-h-0 md:pb-50 md:pt-90">
{/*            <CtaButton
              item={actions.cta1}
              variant="raised"
              color="white"
              size="lg"
              radius="rounded-full"
              data-testid="cta1"
              className="min-w-180"
            />*/}
{/*            <CtaButton
              item={actions.cta2}
              variant="text"
              color="paper"
              size="lg"
              radius="rounded-full"
              data-testid="cta2"
            />*/}
          </div>
        </div>
      </div>
      <div
        className="absolute bottom-0 z-20 h-[6vw] w-full translate-y-1/2 -skew-y-3 transform bg"></div>
    </header>
  );
}

interface CtaButtonProps extends ButtonProps {
  item?: MenuItemConfig;
}

function CtaButton({item, ...buttonProps}: CtaButtonProps) {
  if (!item?.label || !item?.action) return null;
  const Icon = item.icon ? createSvgIconFromTree(item.icon) : undefined;
  return (
    <Button
      elementType={item.type === 'route' ? Link : 'a'}
      href={item.action}
      to={item.action}
      startIcon={Icon ? <Icon/> : undefined}
      {...buttonProps}
    >
      <Trans message={item.label}/>
    </Button>
  );
}

function PrimaryFeatures({content}: ContentProps) {
  return (
    <div style={{ marginTop: '50px', textAlign: 'center' }}>

    <h4 className="text-5xl" style={{ marginBottom: '40px' }}>How to build a website using AI</h4>

  <div
      className="landing-container z-20 items-stretch gap-26 md:flex"
      id="primary-features"
    >

      {content.primaryFeatures.map((feature, index) => (
        <div
          key={index}
          className="mb-14 flex-1 rounded-2xl px-24 py-36 text-center shadow-[0_10px_30px_rgba(0,0,0,0.08)] md:mb-0 dark:bg-alt"
          data-testid={`primary-root-${index}`}
        >
          <MixedImage
            className="mx-auto mb-30 h-128"
            data-testid={`primary-image-${index}`}
            src={feature.image}
          />
          <h2
            className="my-16 text-lg font-medium"
            data-testid={`primary-title-${index}`}
          >
            <Trans message={feature.title}/>
          </h2>
          <div
            className="text-md text-[0.938rem]"
            data-testid={`primary-subtitle-${index}`}
          >
            <Trans message={feature.subtitle}/>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
}

function SecondaryFeatures({content}: ContentProps) {
  const lottieFiles = [
    'storage/homepage/f1-1712879779964.json',
    'storage/homepage/f2-1712881032483.json',
    'storage/homepage/f3-1712880459726.json',
  ];
  return (
    <div className="relative overflow-hidden pt-100">
      <div className="landing-container relative" id="features">
        {content.secondaryFeatures.map((feature, index) => {
          const isEven = index % 2 === 0;
          return (
            <div
              key={index}
              data-testid={`secondary-root-${index}`}
              className={clsx(
                'relative z-20 mb-14 py-16 md:flex',
                isEven && 'flex-row-reverse',
              )}
            >
{/*              <img
                src={feature.image}
                className="mr-auto w-580 max-w-full rounded-lg shadow-[0_10px_30px_rgba(0,0,0,0.08)]"
                data-testid={`secondary-image-${index}`}
                alt=""
              />*/}
              <Player
                src={lottieFiles[index]}
                className="mr-auto w-580 max-w-full rounded-lg "
                data-testid={`secondary-image-${index}`}
                loop
                autoplay
                style={{ maxWidth: '400px' ,maxHeight: '350px',overflow:'visible'}}
              />
              <div className="ml-30 mr-auto pt-30">
                <small
                  className="mb-16 text-xs font-medium uppercase tracking-widest text-muted"
                  data-testid={`secondary-subtitle-${index}`}
                >
                  <Trans message={feature.subtitle}/>
                </small>
                <h3
                  className="py-16 text-3xl"
                  data-testid={`secondary-title-${index}`}
                >
                  <Trans message={feature.title}/>
                </h3>
                <div className="h-2 w-50 bg-black/90 dark:bg-divider"/>
                <div
                  className="my-20 text-[0.938rem]"
                  data-testid={`secondary-description-${index}`}
                >
                  <Trans message={feature.description}/>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ marginTop: '50px', textAlign: 'center' }}>

      <a
        className="button button-primary  button-generate"
        style={{  position: 'relative' }}
        href="/register"
      >
        Unlock All Features
      </a>

    </div>
    </div>
  );
}
function Features({content}: ContentProps) {
  const features = [
    {title:'No code website building',subtitle:'The LansiAI Website Builder makes website building without any coding possible. Thus, simplifying the website creation process, and making it accessible and efficient for users of all skill levels.'},
    {title:'Save time and resources',subtitle:'Our AI website generator significantly reduces the time needed to create a website. By automating design and content generation, what might take hours or days is accomplished in minutes.'},
    {title:'Customization and flexibility',subtitle:'Our Website Builder offers a high degree of customization, allowing users to tweak the design, content, and layout to suit their specific needs. This ensures that the website aligns with the brand identity and goals.'},
    {title:'SEO optimized content',subtitle:'Automatically generate SEO-friendly pages and optimize your site for search engines.Our site builder includes SEO optimization and content generation features.'},
    ]
  return (
    <div style={{ marginTop: '50px',marginBottom: '50px', textAlign: 'center' }}>

      <h4 className="text-5xl" style={{ marginBottom: '40px' }}>Explore the perfect solution for all industries</h4>

      <div
        className="landing-container z-20 items-stretch gap-26 md:flex"
        id="primary-features"
      >

        {features.map((feature, index) => (
          <div
            key={index}
            className="mb-14 flex-1 rounded-2xl px-24 py-36 text-center shadow-[0_10px_30px_rgba(0,0,0,0.08)] md:mb-0 dark:bg-alt"
            data-testid={`primary-root-${index}`}
          >

            <h2
              className="my-16 text-lg font-medium"
              data-testid={`primary-title-${index}`}
            >
              <Trans message={feature.title}/>
            </h2>
            <div
              className="text-md text-[0.938rem]"
              data-testid={`primary-subtitle-${index}`}
            >
              <Trans message={feature.subtitle}/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

interface PricingSectionProps {
  content: LandingPageContent;
}

function PricingSection({content}: PricingSectionProps) {
  const query = useProducts('landingPage');
  const [selectedCycle, setSelectedCycle] =
    useState<UpsellBillingCycle>('yearly');
  return (
    <div className="py-80 sm:py-128" id="pricing">
      <div className="mx-auto max-w-1280 px-24 lg:px-32">
        <div className="md:text-center">
          <h2
            className="font-display text-3xl tracking-tight sm:text-4xl"
            data-testid="pricingTitle"
          >
            <Trans message={content.pricingTitle}/>
          </h2>
          <p className="mt-16 text-lg text-muted" data-testid="pricingSubtitle">
            <Trans message={content.pricingSubtitle}/>
          </p>
        </div>
        <BillingCycleRadio
          products={query.data?.products}
          selectedCycle={selectedCycle}
          onChange={setSelectedCycle}
          className="my-50 flex justify-center"
          size="lg"
        />
        <PricingTable
          selectedCycle={selectedCycle}
          productLoader="landingPage"
          landingPage
        />
      </div>
    </div>
  );
}

function BottomCta({content}: ContentProps) {
  return (
    <div
      className="relative overflow-hidden  py-90 text-white md:py-128"
      data-testid="footerImage"
    >
      <img
        src={content.footerImage}
        alt=""
        width="2347"
        height="1244"
        decoding="async"
        loading="lazy"
        className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
      />
      <div
        className="relative mx-auto max-w-1280 px-24 text-center sm:px-16 lg:px-32">
        <div className="mx-auto max-w-512 text-center">
          <h2
            className=" font-display text-3xl tracking-tight sm:text-4xl"
            data-testid="footerTitle"
          >
            <Trans message={content.footerTitle}/>
          </h2>
          {content.footerSubtitle && (
            <p
              className="mt-16 text-lg tracking-tight"
              data-testid="footerSubtitle"
            >
              <Trans message={content.footerSubtitle}/>
            </p>
          )}
          <div className="hero-form field field-grouped" >
            <div className="control control-expanded" style={{ position: 'relative' ,width: '480px'}}>
              <input
                className="input"
                type="text"
                id="prompt2"
                name="prompt2"
                placeholder="A landing page for a SaaS marketing platform"

              />
              <a
                className="button button-primary button-block button-generate"

                href="/register"
              >
                Generate
              </a>
            </div>
          </div>

{/*          <CtaButton
            item={content.actions.cta3}
            size="lg"
            radius="rounded-full"
            variant="outline"
            color="paper"
            className="mt-40 block"
            data-testid="cta3"
          />*/}
        </div>
      </div>
    </div>
  );
}
function SupportedBy({content}: ContentProps) {
  return (
    <div style={{ marginTop: '50px', textAlign: 'center' }}>
      <h5 className="text-3xl" style={{ marginBottom: '20px' }}>Supported By</h5>
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px' }}>
        <div style={{ width: '150px', height: '150px' }}>
          <img
            decoding="async"
            loading="lazy"
            src="https://framerusercontent.com/images/YAx91h8UPHpRvosTwq7nJQSiN7g.png"
            alt="Zoom"
            style={{ display: 'block', width: '100%', height: '100%', borderRadius: '8px', objectFit: 'contain', imageRendering: 'auto' }}
          />
        </div>
        <div style={{ width: '150px', height: '150px' }}>
          <img
            decoding="async"
            loading="lazy"
            src="https://framerusercontent.com/images/7UtzDGv7H7cjOMzmkIhAtKhbtGQ.png"
            alt="Teams"
            style={{ display: 'block', width: '100%', height: '100%', borderRadius: '8px', objectFit: 'contain', imageRendering: 'auto' }}
          />
        </div>
        <div style={{ width: '150px', height: '150px' }}>
          <img
            decoding="async"
            loading="lazy"
            src="https://framerusercontent.com/images/0U0KsnSerh82m8r1Cws0zYEWB7E.png"
            alt="Google Meet"
            style={{ display: 'block', width: '100%', height: '100%', borderRadius: '8px', objectFit: 'contain', imageRendering: 'auto' }}
          />
        </div>
        <div style={{ width: '150px', height: '150px' }}>
          <img
            decoding="async"
            loading="lazy"
            src="https://framerusercontent.com/images/o2mHKBy9bMxUbkfeRwz4J5Vp0.png"
            alt="WebEx"
            style={{ display: 'block', width: '100%', height: '100%', borderRadius: '8px', objectFit: 'contain', imageRendering: 'auto' }}
          />
        </div>
        <div style={{ width: '150px', height: '150px' }}>
          <img
            decoding="async"
            loading="lazy"
            src="https://framerusercontent.com/images/zsIWlXT09hhqGAGeTsXwjc6Ljw.png"
            alt="WebEx"
            style={{ display: 'block', width: '100%', height: '100%', borderRadius: '8px', objectFit: 'contain', imageRendering: 'auto' }}
          />
        </div>
      </div>
    </div>  );
}
function Goal({content}: ContentProps) {
  return (
    <div className="">
      <div style={{  textAlign: 'center' }}>
        <h4 className="text-5xl">6725+ landing pages already created!</h4>
      </div>
      <div className="pp-goal-main">
        <ul className="pp-goal-top">
          <li><img src="storage/homepage/goal/g1.jpg" alt="" /></li>
          <li><img src="storage/homepage/goal/g2.jpg" alt="" /></li>
          <li><img src="storage/homepage/goal/g3.jpg" alt="" /></li>
          <li><img src="storage/homepage/goal/g4.jpg" alt="" /></li>
          <li><img src="storage/homepage/goal/g5.jpg" alt="" /></li>
          <li><img src="storage/homepage/goal/g6.jpg" alt="" /></li>
          <li><img src="storage/homepage/goal/g7.jpg" alt="" /></li>
        </ul>
        <ul className="pp-goal-bottom">
          <li><img src="storage/homepage/goal/g8.jpg" alt="" /></li>
          <li><img src="storage/homepage/goal/g9.jpg" alt="" /></li>
          <li><img src="storage/homepage/goal/g10.jpg" alt="" /></li>
          <li><img src="storage/homepage/goal/g11.jpg" alt="" /></li>
          <li><img src="storage/homepage/goal/g12.jpg" alt="" /></li>
          <li><img src="storage/homepage/goal/g13.jpg" alt="" /></li>
          <li><img src="storage/homepage/goal/g14.jpg" alt="" /></li>
        </ul>
      </div>
    </div>);
}
